import * as React from "react";

const ButtonLink = ({ href, text, className }) => {

  return (

    <a className={`flex flex-row group text-white px-6 py-2 border-white border border-solid hover:bg-black hover:border-black transition ease-in-out duration-300 ${ className }`}
       href={href}
       target={`_blank`}
    >
      <span className={`uppercase group-hover:text-white transition ease-in-out duration-300 mr-1`}>{ text }</span>

      <svg className={`w-6 h-6 text-white group-hover:text-red transition ease-in-out duration-300`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
      </svg>
    </a>
    
  );

}


export default ButtonLink;