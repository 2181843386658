import React, { useState, useRef, useEffect } from 'react';
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Transition from "../utils/Transition";


const Header = ({ siteTitle }) => {

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [top, setTop] = useState(true);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <header className={`absolute w-full z-30`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-24 md:h-28">

          {/* Desktop left navigation */}
          <nav className="hidden lg:flex lg:grow w-1/3">
            <ul className="flex grow justify-start flex-wrap items-center gap-12">
              <li>
                <Link to={'/'}
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">Home</Link>
              </li>
              <li>
                <Link to={'/our-salon'}
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">Our Salon</Link>
              </li>
              <li>
                <Link to={'/services'}
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">Services</Link>
              </li>
            </ul>
          </nav>


          <div className="shrink-0 mr-4">
            {/* Logo */}
            <Link to="/" className="block" aria-label="">
              <svg className={'h-16 md:h-20'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 714.33 158.74">
                <circle cx="79.37" cy="79.37" r="70.87" fill={"#e30613"}/>
                <path d="M194.13,226.77v19.77a72.22,72.22,0,0,0-49.8-19.77c-39.72,0-71.92,31.73-71.92,70.87s32.2,70.86,71.92,70.86a72.17,72.17,0,0,0,49.8-19.77V368.5H769.48V226.77Z"
                      transform="translate(-63.78 -218.27)"
                      fill={"#000"}/>
                <path d="M769.48,226.77V368.5H194.13V348.73a72.17,72.17,0,0,1-49.8,19.77c-39.72,0-71.92-31.72-71.92-70.86s32.2-70.87,71.92-70.87a72.22,72.22,0,0,1,49.8,19.77V226.77H769.48m8.63-8.5H185.5v11.14a81,81,0,0,0-41.17-11.14c-44.42,0-80.55,35.6-80.55,79.37S99.91,377,144.33,377a81,81,0,0,0,41.17-11.15V377H778.11V218.27Z"
                      transform="translate(-63.78 -218.27)"
                      fill={"#e30613"}/>
                <path d="M345.3,226.77a118.67,118.67,0,0,0-85,35.75V226.77h-34V368.5h34V345.83a85,85,0,0,1,85-85Z"
                      transform="translate(-63.78 -218.27)"
                      fill={"#fff"}/>
                <circle cx="272.52" cy="121.2" r="31.18" fill={"#e30613"}/>
                <path d="M415.31,251.12l2.16-3.27h1l1,1-.24,1.86-1.2,1.61-.68,3.31-.77,2.68,1.45,6.78.7,3.05.74.95v1.24h.47l.74,4.5.43,1.37,1.72,6.4.68,4.5,2,3.55v1.61h.25l.43.25h.25v.66l-.49.33h-.19l.19,2.56.77.33v.33l.92,3.22.28,1.61.43,1.61v.58l1.2,3.55v1l.74.9,1.2,3.93.53,1.24-.77,1.94L429,316l-1.7-1.24h-.43l-.24-.37v-1h.43l.49.33h.28l.43-1-.43-1-1,1v.37h-.52l-.25-.7v-1.28h1l.24-.33v-.34l-.24-.57h-2.84l-1-1.94.18-.71h.25l.52,1h.25v-1l-.25-.57-1.45-.67-.71-2.56-.74-1.94-.46-1.94-.24-.33-.25-2.56-.71-1h-3.39l-5,1.32-1,.29h-1.2l-1.88,1.61-1.7.33-.21.33-2.9,1.61-.68,2.27v1.28l.68.58v.7l-1.76-.2-.52,1.53-.62,2.56-1,.24v1l-.24.37-1,3.8-.43,1v.95l-.77.66-1.17,2.89-.71,2.19-1.2,3-1,.33-1-.82.07-2.15.24-.33,1.7-3.47.43-.33,1-3.55.71-.33v-.95l.92-2.27.52-2.19.25-3-.25-.24h-2.15L394,306v-.37l1-.91.92-2.89,2.22-2.27h.68L400,298l.28-1.61.43-1.94,1.7-5.74.52-.7-.28-.91,1.48-1.28.19-.66-.71-.33v-.95l.71-1,.25-1.61,1-1.57-.21-2.23.7-.66,1-.33.25-.24-.71-.71.18-1.61v-.33l.77-1.28.43-1.24v-.7l.28-.33.25-1.61.25-.25.67-.33-.67-.7.67-1.94h.53l.68-1.61v-2.19l.52-2.27-1-4.5-.77-.91v-1.61l1.42-1,1.51-1.69,1.27-.17.49,1.53.43,2.48ZM413.43,270l-.52-.25h-.25v.58l.25,1-.25.25H412l-.7,3.22-1.21,2.27-1.94,5.74-.71,3-.95,1.86-.25,1.61v.33l-.49,1v.25l-.46,2.6H405l-.49.37.24.91,1.7.33,2.83-1.61,2.68-.66.25-.33,2.9-.33,2.84-.62.24-.33.25-1.61h.46V289H418v-.33l-.68-3.22-.53-1v-.57l-.49-.33v-.33l-.19-2.23-1-4.55.49-.95.25-1.24h-.5l-.24.33v.25l-.19.33h-.77l-.43-2.19,1-.33-.28-.37h-.68Zm5.49,7.43-1.45-.33-.46.33.28.95.68,1-.25,1.28.95,1.28.25,1.94.49,2.19,1.21,4.25-.25.33-.25.25v.33l.93,1,1.2-.7,1.45.7.28-.33-1.73-.7-.19-.33-.49-1.28-.25-1.61v-.91l-.7-1.28-.74-2.6.74-.95-.25-.33-.25.66h-.46l-.25-.66v-.33l.47-.38.24.38V281l-.95-1v-.33l.71-.62v-.33h-1v-1.28Zm3.85,17.35-.71-.33-.24,1,.95,3.8-.24,1.24h.24l.93,1.36.52,1.61,1.45,3.8.19,1.28,1,.33.19-.33-.19-.95-.52-.9-.25-.67-1.2-2v-1.94l-.43-1.24-.25-2.23Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M458.81,247.4l1-1h.52l1.76,3.38-.77,4.38-.74.33.74,1.7-.74,1v2.68l-1,2.07.49,2.68-.49.33h-.25l-.25.33.25,1.37-1,2.39v1.69l-.25,1.7-.53.66h-.24v.66l1,.7v1.32l-.5.38-.52,3-.25,2,.25.38-.5,2.35v1.69l.74,2-.24.33-.5.37-.52,1.65.52,1.36v2h-.24l-1,.7v.33l.25.33h.49l.28,1.36.24,4.38-.52,4.42-.74.66.74,1v1l-.49.71.24,2.35-.49,3.39-.25,1,.25,1.32-.89,2.15-.87-.46v-1l.5-1-.5-2,.5-1.37L454,311l.49-7.1.53-.33v-.33l-.53-1v-2.35h-.24l-.5.33-.27-.66v-.7h.77l.49-3.35.28-.7-.53-.66v-.33l-.24-.37-.77-1.7-.25-1.32v-.7h.52l.5.7-.25,1,.25.33.49-.66-.25-4-.49-2-.25.33-1.26.33V286l-.52.71-1,1.69-.74.66v.66h.49l-.24,1-1,1.37-.74,1.69-1.27,1.32-1.78,3.39-1,1.36-1,1.69-1.51,1.37-1.26.66h-.77v1.36l-2,2.35-1.26,1-2.71.74-.83-2.44,3.79-9.83,1.26-4,.49-.66.25-1.7,4.32-8.13,2.52-3.39,2.25-2.35.78-.33.73-1.37,1-.7,1.79.37,1.51-3.05.25-2-.25-1,.25-1.36,1-.33V266l-.24-.33v-.38l.24-1.32-.52-1.69-.49-.7.77-2.69.24-1.69.5-1,.24-2.36-.24-2,1.75-2.73h.53l.25-.33Zm-9.16,36.8-2.49-.66-.77.66.12,1.11-2.59,3.88-.77,1.86v.33l-.49.71v.66l-1.51,1.69-.5,3.39-.77,1.36v2h.49l1.79-2.39v-.33l.5-.66h.77l2-4.05.77-2.4,1.51-2.68,1.7-2.48v-.66Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M482.7,279.65l.68-.37h.77l1,.37.62.66,1.08,1.53.8.91,1.14,1.53-.12.87-.8-.09-.65.17-1-.79-.86-.74-1.14-.25-1.08.91-.77.46-.19.82-.74.42-.46,1.36-.37,1-.49.66-.65,1.69-1,1.62-.12,1.28-.55,1.15-.77.7-.81.17-.77,1.53-.24,1.32-.77.7-.5,1.53-.24.66-.84,1.45-.55.24-.65,1-1.26,1.48-.93,1.45-1.14.33-.95,1.11-.5-.66.13-1.61.61-1.11.25-1.37-.25-1.32-.12-1.36.65-2.89V297l.55-1.41-.37-1.11.31-1.28-.25-1.78.25-1.61.49-2.27v-1.61l.28-1.07.68-.95.25-1.12.83-.16.49-.83v-.7l.77-.66.62.41h.65l.55.7-.55,1.61.24,1-.37.79-.06,1,.31,1-.37,1.24-.4,1,.18,1-.37,1.15-.43,1.28.25,1.2-.25,1.32-.24,2.65.73-.95.84-.5.68-1.2,1.07-1.77.9-.91.12-1,.9-1.86L478,289l.43-1.61.53-1.12.24-1.24.5-.95.64-1.28.68-.57.13-.75.71-.45.36-.83Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M496,278.79l.5-.66,1.51.33.52.66-.28,4.09-.24.66.24,1.36-.74,1.69.74,1.32-.49,4.09v3.39l-.49.33v1l.74,1.36-1.76,2,.49,1-.49,2.35L495,308.56h-.25l-1-.74-.49-2.31,1-1.7v-1l.52-4v-3.1l1-3.39-.77-1.69.49-1.69-.49-1,.49-2.35-.06-1-.74-.58-1.2-2.11-1-.33-2,.66L487.94,285l-1.79.33-.19-.78.71-1.61,2.25-1.7,1.54-.74,1.52-.66,2.25-1.36ZM494.5,259.5l.74-1h.53l2,1.32.49,3.39,2,1.36,1.26,1.36v.66l-1.26.33-2.28,2.4h-3l-1.51-.33-2.28-2.4.49-1.36.28-.33,1-2.68.53-.33Zm-1,50.1.49-.71,1.26.33.53,1.7-.77,1.36v.33l-1.51.33-.25-.33Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M517.78,275.81l2.28-2.35,1.08-.25,1.2.25.74,2.35v2.07l-.5,2.35-1.51,1-1.26-.66-.25.33.74,1-.24.66h-.77l-.25-1,.25-.71-.25-1h-.74l-1.79,2.36h-.24l-.25.33-.53,1.69L514,286l-.49,1.7v.7L512.23,290l-.5,3.06-1,3.05v1.7l-.83,1.19-.19,1.49-.49,1.69-.47,1.86.47.87.74-.66,2.28-3.06,2.28-3.38,1.69-2.36,1.76-2.89,1.73-2.35,2.37-4,2.81-4h.74l1,1.36-.12,1.86.12,2.19-.49,3.71v2.4l-.52,1.32,1.75,4.75-.24,1.36,1.51,1.37,1.72.16,2.25-2,2.41-1.9,1.72-2.36,3-3.63,3-3.8,1.14-.87-.77,2.73L541,294.77l-.77.33-1.51,2.73-1,1.19-1.51,1.49-.68.87-.89.82-2.16,2.4-1.88,1.94-2.41,1.28-2.83.5-1.51-.71-.77-2-1.27-1.69,1-1v-.33l-1.26-1.45v-2.31l.24-1-.49-1.37.25-.66-.77-1-.5-1h-.24l-3.79,4.38-1.76,3.38-1.51,2v.33l-1,1-.77,1.69-1.76,1-1.51,2.07-1.26,1-.25.7-1.54.33-.49-1,1.75-7.1.78-2.69.43-2.15.52-1.77,1.05-2.19.28-.33.24-2.35,1.76-4.75,1-1.37v-.66l.24-.7,1.14-2.52L515,279.9l.25-.7,1-1.24Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M546.51,282.75v-.33h.77l.49,2.73v2.35l-1,1v1l.77,2v1l-.52,2,.52,1-.24,3.71.74,2,5.05-5.08,3.79-2.69h.77l2.25-2.72,2-2.36,1,.33v.33l.24,1.7,1.27,2.72.24,2,.5,1.69,1,2.35h.5l.77-1,1.88-.74,1.38-1.28,2.29-1.24,1.51-1.36,1,1-1.75,3.3-1.79,1-5.55,5.08h-1.76l-.24.33-.53,1-2.25,1.36h-.52l-1-1.69v-1.37h-.25l-.24-.33-.25-4.05-1.76-2-2.52,1.69h-.53l-1.51,1.37-1.76,2-2,1.69-1,1.33-.74.37L545,308.48l-2.53,2-.5-.33v-4.74l.25-2,.49-.66v-1l.78-1-.53-2.4.28-1.36L543,294.6l.53-2-.53-.66,1.54-5.08Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M624.86,249.88l3.86.08,3.76,1.45,1,.49,2.28.25,1.88,1.44,1.2.66L640,257l-.06.66.43.66.77-.16-.12,1,.49.5h.22l-.34,2.72-2.53,5.25V268l-1.51,1.44-1-.33-1.69,1.69-.81,2.19-1.07.42-1,.08-.77,1.61-1.33.33L628,276.76l-.43-.49-1,1.15-.83.17-2.38.87-.15.57,1.66,1.45,5.12,2.64,2,2.19h1.27l.43.42.77-.09,1.08,1.7,1.69.66,4.29,2,2.4,3.76,1.7.33,5.39,4.38,1.45.7.37.74.31,1.86-.06.33.18.37.53-.2.25.37-.38,1,1.39,1,.25.33-.13.33-1,.42-1.27-.58-.83.41-1.45-.66.13-.95.83-.17-.19-.49.13-.95-.19-.41H650l-.19-.33v-.34h-.28l-.49.5.89.87-.18,1.32-1-.5-.52-.49.15-.33-.27-.37.27-.33-.27-1.7.4-.66-.28-.33-3.51-1.86-1-.24-.43-.87-.77.16-.31.25.68.54-.13,1,.71.87v.66l-.37.33-3.54-2.6-.43-.79.12-.33.25-.25.49.09,1.14,1-.31-1.45-.83-1.61-1.26-.25-.37-1.19-1.89-.83-.27-.37-.68-.17-.12.71.18.33,1.27.33.61.86,2.16,1.49v.37l-.65.58-3-.41-1.69-2.56-3.18-1.78h-1.51l-1.14-1.07-2.59-1.7-1.32-.62-2.59-1.23-.87-.54-3.67-1.61h-1.51l-.43-.42-1.42.71-.86,2.85-1.85,7.8-.31,2.27-.74,4.38-1,7.27-.53.29-1.17-.7-.4,1-1.63.25-.9,1.44-1.14.83-.61-1.53.61-2.93-.12-1.12.12-.66-.12-.7.28-2.27.61-.58v-.37L604,301h-.25l.49-.7-.61-.82.37-2,.12-1.36-.31-1.12.74-2.18-1.39-1-.06-1.4,1.85-.62.86-2.19.53-.91,1.45-1.28.61.58.77-1.28v-1.69l-.24-.42-1.14-.57-.25-.42,1.11-2.48,1.33-.74.83-2.19,2-7.72.5-1.69-.13-1,1-3.23.24-4-.52-.13-.43-.49-1.82-.91-3.36.66L606,260.2l-.74,1.57-1.33,2.4-1.14.82-.24-.16-.9-1.16,1.27-3.84.89-.83.49-1.61,1.82-1.94,2.41-1.53h1.38l.28-.66,1.57.09.44.41.12-1,.89-.83,3-1,3.42-.5.56-.58,2.77.34h1.51Zm1.95,4.21-1-.17-.56.83-3.91.21-.43,1.15-.34,2.73-.62.83.13,1.11-.68,3.22-.47,1.32.19.38-1.39,4.79-1,2.23-.07,2-.8.83-.12.7,2.53.16,4.22-1,.89,1,.5.08L623.6,275l2.65-1.12,1.08-.08,1.76-1.61,2.77-2.11,2.47-2.85,1.2-3.14-.46-.78.34-.58.37-1L634.39,259,633,258l-1-.82H631l-1.33-1.37L627.7,255l-.31.25ZM644,298.94l.3-.37,3.36,1.86.25.37-.12.33-.5.66-2.71-1.36h-1l.13-.91.49.08Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M670,283.62l.9.66,1.51,1.36,1.2-.74,1.32-.08,2.53.41h2.1l1.08-.17.55,1.08-.43.7-1.33.91-1.48.49h-.8l-1.08.38-.55.24-.46,1.28-.68.5-1.08.08-.31,3.64-.83.33V296l-.31.82-1.51,2.65-1,1.07-1,1.45-1.45,1.69-1.14,1.69-1.14.42-.25.86-.89.33-.87.87-1,1.24-.84.54-1.44.33-1.05-1.45-1-1.69.12-1.28.07-1.45-1.15-2-.24-2.77v-2.39l.49-3.39-1.26.37-1.08.91-.8.5-1.7,1.28-.28-1.78,1.15-2.19,1.51-1.11,1.26-1,1.08-1.52,1.76-1.78,1.32-1,1.33-1,1.32-.46-.06,1.45-1.39,1.94-.37,2.06.31,1.86.06,1.94-.06,2,.4,2.19.56,1.61.95.74.25,1.45,1.26-.25,1.08-.5,1.14-1.2,1-.66.71-1,.68-1.61,1-1.4-.06-1.36.25-.95-.31-1.61-.19-2-.89-2.51-1.45-.87-1-.25-1.11-.5-1.2-.95,1.05-.9,1.08-.5,1.14-.37,1.2.08,1-.16Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M686.48,285.23l1.82-4.09,2.47.46.24,4-1.26,2.68-.12,2L689.5,293l-.83,5.08,2.22-2.1,2.78-4.05.49-.21,1.48-2.35,1.82-2.6,1.14-1.12.43-1.11,1-.33,1.51,1,.24,1.69,1,1.32.25,3.39,2,3.76v1.32l.74,1,1,.66,1.51,3.06h.52l1.51,1h1l1-2.39-.25-1,.49-.71.53-.33.49-1.69-.49-2.68,1.51-1-.49-.66.49-1.69,2-.37-.52-3,1.51-3.38,1.51-1.37.52.33-.24,2.4-1,4.38-.77,5.08-.74,1.36v.33l-1,1.69v1.37l-.49,2.35-1,1.36v.33l-2.53,2.69-1.51,2.06h-.24l-1.79,1-1.63-.33-.5-1-.27-1.69-.25-.33h-1.51l-1.27-1.32-.24.33-1.27-.33-.24-2.4-.71-.74-.74.08-.34-1.36-1.76-2.36-.83-2.14-1.57-.91h-.74l-.77.66-1.51,2.72-.5.33-.52,1.7-2,2.72-1.27,1-1.26,2.36h-.25l-.77.7-1,1.69h-1.75l-.25-.33,1.26-3.71v-1.37l.25-1.69,1-4.42.37-3.63.24-4.13.44-2Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M732.1,277l2-2.68.77.33h.25V276l-.53,2.35-.49,1-.25.7-.77,1.36v.66l-.74,1.36-.46,1.61-.8,1.08v3.38l-.28,1.7-.74.7v1l1,.25,1.94-2.69,1.51-2.64-.13-2.44.84-.7.24-4.29-.12-1.45,1-2,1.57-2.6,1.39.57.89.75V278l-.77.66V279l.25,1.37-.5.66-.52,3.05-1.26,1.37-.74,2-.5.66-.58,1.7,1-.5.8-.83.71-.7,1.07-.33,1.7-1.53,1.32-.82.71-.87,1.51-1.16,1.14-.95,1.76-.41-.77,1.94-.8,1.12-1.08.74-1.2,1.28-.49,1-2.29,1.36-.24.67-2.1.37-.68,1.32-1,1-1.21.74-2.34,2.32-1.51.66L730.35,298v1.69l-.25.33h-.25v.34l.5,2.31.43,1.53.58.9,1.51.34,1.27-.5.43-.95.89-.33.5-.5,1.26-.24,1.45-1.2,1.54-2,.49-.24,2.35-2,1.51-2.31,1.45-1.86,1-.33.31,1.78-1.82,2.56L743,300l-1.79,1.28-.25,1.08-1.76,2.06-1.26,1-2,.33-.25,1v1l-1.51.33-1,1h-.74l-3.67.7-2-1.85-.37-2.56-.49-1V302l-.28-1,.28-.7V300l-.53-1.69.53-3.05v-2l.74-2,.24-2.36.28-.33,1-2.72.77-3.39,2-3.72.77-.66v-.7Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M593.24,346.29v-21h3.15v8.28h6.21v-8.28h3.16v21H602.6V337.1h-6.21v9.19Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M623.15,346.29h-3.43l-1.37-4.76h-6.27l-1.29,4.76h-3.37l6.1-21h3.35ZM617.33,338l-2.16-7.8-2.12,7.8Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M624.72,346.29v-21h3.15v21Z" transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M630.91,346.29v-21h6.65a8.45,8.45,0,0,1,3.65.57,4.26,4.26,0,0,1,1.8,2,7.33,7.33,0,0,1,.7,3.35,6.68,6.68,0,0,1-1.07,4,4.71,4.71,0,0,1-3.06,1.89,8.48,8.48,0,0,1,1.69,1.76,27.23,27.23,0,0,1,1.81,3.42l1.9,4.1h-3.77l-2.29-4.57a33.5,33.5,0,0,0-1.68-3.11,2.66,2.66,0,0,0-.94-.86,3.88,3.88,0,0,0-1.59-.24h-.65v8.78Zm3.15-12.13h2.35a10.54,10.54,0,0,0,2.73-.21,1.79,1.79,0,0,0,1-.84,3.27,3.27,0,0,0,.37-1.68,3,3,0,0,0-.37-1.61,1.84,1.84,0,0,0-1-.84,11,11,0,0,0-2.56-.16h-2.48Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M656.72,338.56l3.06,1.31A9.87,9.87,0,0,1,657.4,345a5.68,5.68,0,0,1-4.11,1.62,5.88,5.88,0,0,1-5-2.59,13.45,13.45,0,0,1-2.19-8.09q0-5.39,2.2-8.43a6.06,6.06,0,0,1,5.15-2.64,5.67,5.67,0,0,1,4.41,2,8.88,8.88,0,0,1,1.85,4.18l-3.12,1a4.85,4.85,0,0,0-1.2-2.62,2.88,2.88,0,0,0-2.11-.91,3.29,3.29,0,0,0-2.83,1.67q-1.1,1.67-1.09,5.49t1.06,5.66a3.21,3.21,0,0,0,2.78,1.67A2.74,2.74,0,0,0,655.4,342,7.09,7.09,0,0,0,656.72,338.56Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M662.45,325.26h3.16v11.39a31.09,31.09,0,0,0,.11,3.46,3.64,3.64,0,0,0,1,2.15,3.25,3.25,0,0,0,3.87.23,2.73,2.73,0,0,0,.95-1.5,19.91,19.91,0,0,0,.26-4.09V325.26H675v11.05a32.45,32.45,0,0,1-.41,6.12,5.26,5.26,0,0,1-1.85,3.05,5.85,5.85,0,0,1-3.8,1.17,6.56,6.56,0,0,1-3.83-1,5.41,5.41,0,0,1-2-2.72,22.62,22.62,0,0,1-.62-6.48Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M681.8,346.29V328.82h-4.65v-3.56h12.44v3.56H685v17.47Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M695.17,346.29V328.82h-4.65v-3.56H703v3.56h-4.63v17.47Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M705,346.29v-21h11.63v3.56h-8.47v4.66h7.87V337h-7.87v5.71h8.75v3.55Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M719.62,346.29v-21h6.65a8.45,8.45,0,0,1,3.65.57,4.26,4.26,0,0,1,1.8,2,7.33,7.33,0,0,1,.7,3.35,6.68,6.68,0,0,1-1.07,4A4.72,4.72,0,0,1,728.3,337a8.69,8.69,0,0,1,1.68,1.76,27.23,27.23,0,0,1,1.81,3.42l1.9,4.1h-3.77l-2.29-4.57a33.5,33.5,0,0,0-1.68-3.11,2.66,2.66,0,0,0-.94-.86,3.88,3.88,0,0,0-1.59-.24h-.65v8.78Zm3.15-12.13h2.35a10.54,10.54,0,0,0,2.73-.21,1.79,1.79,0,0,0,.95-.84,3.27,3.27,0,0,0,.37-1.68,3,3,0,0,0-.37-1.61,1.84,1.84,0,0,0-1-.84,11,11,0,0,0-2.56-.16h-2.48Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
                <path d="M734.6,339.44l3.09-.4c.35,2.68,1.48,4,3.41,4a3.05,3.05,0,0,0,2.26-.83,2.81,2.81,0,0,0,.82-2,2.42,2.42,0,0,0-.29-1.22,2.16,2.16,0,0,0-.87-.82,22.4,22.4,0,0,0-2.84-1.09,8.9,8.9,0,0,1-3-1.46,5.36,5.36,0,0,1-1.49-2.07,6.84,6.84,0,0,1-.56-2.76,6.67,6.67,0,0,1,.71-3.09,4.94,4.94,0,0,1,2-2.08,6.25,6.25,0,0,1,3.09-.71,5.66,5.66,0,0,1,4.32,1.65,7,7,0,0,1,1.64,4.72l-3.17.19a4,4,0,0,0-.9-2.37,2.69,2.69,0,0,0-2-.67,2.89,2.89,0,0,0-2,.59,1.82,1.82,0,0,0-.69,1.45,1.92,1.92,0,0,0,.62,1.45,8.56,8.56,0,0,0,2.88,1.29,11.41,11.41,0,0,1,3.45,1.6,5.32,5.32,0,0,1,1.62,2.16,8.36,8.36,0,0,1,.56,3.22,7,7,0,0,1-1.53,4.61,5.85,5.85,0,0,1-4.79,1.86Q735.25,346.65,734.6,339.44Z"
                      transform="translate(-63.78 -218.27)" fill={"#fff"}/>
              </svg>
            </Link>
          </div>


          {/* Desktop right navigation */}
          <nav className="hidden lg:flex lg:grow w-1/3">
            <ul className="flex grow justify-end flex-wrap items-center gap-12">
              <li>
                <Link to="/pricelist"
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">Pricelist</Link>
              </li>
              <li>
                <Link to="/vip-at-home"
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">VIP At Home</Link>
              </li>
              <li>
                <Link to="/contact"
                      className={`font-medium text-white uppercase flex items-center transition duration-300 ease-in-out hover:text-red lg:text-sm xl:text-base`}
                      activeClassName="text-red-600 hover:text-red-500">Contact</Link>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex lg:hidden">

            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-6 h-6 fill-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll no-scrollbar bg-black"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-4">
                  <li className={"border-b border-gray-900"}>
                    <Link to={"/"} className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">Home</Link>
                  </li>
                  <li className={"border-b border-gray-900"}>
                    <Link to={"/our-salon"} className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">Our Salon</Link>
                  </li>
                  <li className={"border-b border-gray-900"}>
                    <Link to="/services" className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">Services</Link>
                  </li>
                  <li className={"border-b border-gray-900"}>
                    <Link to="/pricelist" className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">Pricelist</Link>
                  </li>
                  <li className={"border-b border-gray-900"}>
                    <Link to="/vip-at-home" className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">VIP At Home</Link>
                  </li>
                  <li className={"border-b border-gray-900"}>
                    <Link to="/contact" className="flex justify-center font-medium text-gray-300 hover:text-white transition duration-300 ease-in-out py-4 uppercase">Contact</Link>
                  </li>
                  <li>
                    <Link to="/booking" className="flex justify-center text-red py-4 uppercase hover:text-white transition duration-300 ease-in-out group" >Make A Booking <span
                      className="tracking-normal text-red-600 group-hover:text-white group-hover:translate-x-0.5 transition duration-300 ease-in-out ml-1">
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                      </svg>
                    </span>
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>

          </div>

        </div>
      </div>
    </header>
  );

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
